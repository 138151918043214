import { SVGProps, Ref, forwardRef } from "react";

const FormatAlignLeftIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="none"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Format Align Left Icon"
    {...props}
  >
    <title id="Format Align Left Icon">Format Align Left Icon</title>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M16 10H3m17-4H3m17 8H3m13 4H3"
    />
  </svg>
);

const ForwardRef = forwardRef(FormatAlignLeftIcon);
export default ForwardRef;
