import { SVGProps, Ref, forwardRef } from "react";

const TradeInNoEstimateIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-label="Trade-In No Estimate Icon"
    ref={ref}
    {...props}
  >
    <g clipPath="url(#clip0_23925_143)">
      <path
        d="M37.1457 20.1549H35.1166C35.1166 17.6123 33.048 15.5437 30.5054 15.5437C27.9629 15.5437 25.8943 17.6123 25.8943 20.1549H23.8652C23.8652 16.4934 26.844 13.5146 30.5054 13.5146C34.1669 13.5146 37.1457 16.4934 37.1457 20.1549Z"
        fill="#2F4575"
      />
      <path
        d="M43.4175 19.3625L45.1452 16.4832H46.5397C47.1 16.4832 47.5543 16.0289 47.5543 15.4686C47.5543 14.9083 47.1 14.4541 46.5397 14.4541H44.5707C44.2143 14.4541 43.8841 14.6411 43.7007 14.9467L40.8878 19.6348C40.5996 20.1152 40.7553 20.7384 41.2358 21.0268C41.3991 21.1248 41.5792 21.1715 41.7567 21.1715C41.7713 21.1715 41.7857 21.1693 41.8002 21.1687C41.8173 21.1695 41.8342 21.1713 41.8514 21.1713C43.877 21.1713 45.525 22.8192 45.525 24.8447V34.3315H42.6953H35.1943H30.5831V28.5952C30.5831 28.0349 30.1289 27.5807 29.5686 27.5807H18.3174C17.7571 27.5807 17.3029 28.0349 17.3029 28.5952V34.3315H12.6917H5.19076H2.36111V24.8447C2.36111 22.8192 4.009 21.1713 6.03458 21.1713C6.05191 21.1713 6.0687 21.1695 6.08576 21.1687C6.10034 21.1693 6.11472 21.1715 6.1293 21.1715C6.30693 21.1715 6.48688 21.1247 6.65022 21.0268C7.13074 20.7384 7.28643 20.1152 6.9982 19.6348L4.18544 14.9467C4.00208 14.6411 3.6719 14.4541 3.31542 14.4541H1.34657C0.786268 14.4541 0.332031 14.9083 0.332031 15.4686C0.332031 16.0289 0.786268 16.4832 1.34657 16.4832H2.7411L4.46868 19.3625C2.0835 20.0449 0.332031 22.2434 0.332031 24.8447V35.3461C0.332031 35.9064 0.786268 36.3606 1.34657 36.3606H4.17622V39.0965C4.17622 40.6899 5.47261 41.9863 7.06599 41.9863H10.8165C12.4099 41.9863 13.7062 40.6899 13.7062 39.0965V36.3606H18.3174H29.5688H34.1799V39.0965C34.1799 40.6899 35.4763 41.9863 37.0697 41.9863H40.8202C42.4136 41.9863 43.71 40.6899 43.71 39.0965V36.3606H46.5397C47.1 36.3606 47.5543 35.9064 47.5543 35.3461V24.8447C47.5543 22.2434 45.8027 20.0449 43.4175 19.3625ZM11.6772 39.0965C11.6772 39.5711 11.291 39.9572 10.8165 39.9572H7.06599C6.59137 39.9572 6.2053 39.5711 6.2053 39.0965V36.3606H11.6772V39.0965ZM19.332 34.3315V29.6098H28.5542V34.3315H19.332ZM41.6809 39.0965C41.6809 39.5711 41.2948 39.9572 40.8202 39.9572H37.0697C36.5951 39.9572 36.209 39.5711 36.209 39.0965V36.3606H41.6809V39.0965Z"
        fill="#2F4575"
      />
      <path
        d="M38.009 31.5786C35.8986 31.5786 34.1816 29.8617 34.1816 27.7512C34.1816 25.6408 35.8986 23.9238 38.009 23.9238C40.1195 23.9238 41.8364 25.6408 41.8364 27.7512C41.8364 29.8616 40.1195 31.5786 38.009 31.5786ZM38.009 25.9529C37.0175 25.9529 36.2107 26.7597 36.2107 27.7512C36.2107 28.7428 37.0175 29.5495 38.009 29.5495C39.0006 29.5495 39.8074 28.7428 39.8074 27.7512C39.8074 26.7597 39.0006 25.9529 38.009 25.9529Z"
        fill="#6E8DC2"
      />
      <path
        d="M9.87818 31.5786C7.76775 31.5786 6.05078 29.8617 6.05078 27.7512C6.05078 25.6408 7.76775 23.9238 9.87818 23.9238C11.9886 23.9238 13.7056 25.6408 13.7056 27.7512C13.7056 29.8616 11.9886 31.5786 9.87818 31.5786ZM9.87818 25.9529C8.8866 25.9529 8.07986 26.7597 8.07986 27.7512C8.07986 28.7428 8.8866 29.5495 9.87818 29.5495C10.8698 29.5495 11.6765 28.7428 11.6765 27.7512C11.6765 26.7597 10.8698 25.9529 9.87818 25.9529Z"
        fill="#6E8DC2"
      />
      <path
        d="M41.7573 21.1705H6.12783C5.80945 21.1705 5.50961 21.0211 5.31786 20.7669C5.1262 20.5127 5.06487 20.1833 5.1523 19.8773L7.93122 10.1507C8.6271 7.71548 10.8819 6.01465 13.4144 6.01465H34.4706C37.0032 6.01465 39.258 7.71539 39.9538 10.1507L42.7328 19.8772C42.8202 20.1833 42.7589 20.5127 42.5672 20.7668C42.3755 21.0211 42.0757 21.1705 41.7573 21.1705ZM7.47283 19.1414H40.4123L38.0027 10.7081C37.5546 9.13934 36.102 8.04373 34.4706 8.04373H13.4144C11.7831 8.04373 10.3305 9.13934 9.88227 10.7082L7.47283 19.1414Z"
        fill="#6E8DC2"
      />
    </g>
    <defs>
      <clipPath id="clip0_23925_143">
        <rect
          width="47.2222"
          height="47.2222"
          fill="white"
          transform="translate(0.333984 0.388672)"
        />
      </clipPath>
    </defs>
  </svg>
);

const ForwardRef = forwardRef(TradeInNoEstimateIcon);
export default ForwardRef;
