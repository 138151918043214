import { SVGProps, Ref, forwardRef } from "react";

const NotificationActiveIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Notification Active Icon"
    {...props}
  >
    <title id="Notification Active Icon">Notification Active Icon</title>
    <path d="M18 11.2c0-1.38-.632-2.702-1.757-3.677C15.118 6.548 13.592 6 12 6c-1.591 0-3.117.548-4.242 1.523C6.632 8.498 6 9.821 6 11.2c0 2.282-.566 3.95-1.272 5.145-.804 1.36-1.207 2.041-1.19 2.204.017.186.05.244.202.355.131.096.794.096 2.118.096h12.284c1.325 0 1.987 0 2.119-.096.15-.11.184-.17.202-.355.016-.163-.386-.843-1.19-2.204C18.565 15.15 18 13.482 18 11.2Z" />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M15 19a3 3 0 1 1-6 0m4.797-12.761a2.5 2.5 0 1 0-3.593 0M2.547 8.323a4.007 4.007 0 0 1 2.032-3.52m16.874 3.52a4.007 4.007 0 0 0-2.032-3.52m-1.42 6.397c0-1.38-.633-2.702-1.758-3.677C15.118 6.548 13.592 6 12 6c-1.591 0-3.117.548-4.242 1.523C6.632 8.498 6 9.821 6 11.2c0 2.282-.566 3.95-1.272 5.145-.804 1.36-1.207 2.041-1.19 2.204.017.186.05.244.202.355.131.096.794.096 2.118.096h12.284c1.325 0 1.987 0 2.119-.096.15-.11.184-.17.202-.355.016-.163-.386-.843-1.19-2.204C18.565 15.15 18 13.482 18 11.2Z"
    />
  </svg>
);

const ForwardRef = forwardRef(NotificationActiveIcon);
export default ForwardRef;
