import { SVGProps, Ref, forwardRef } from "react";

const KijijiCarIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    {...props}
  >
    <path d="m19.4 9.2-.6-2.5c0-.1-.1-.3-.2-.3-.1-.1-.3-.1-.4-.1-.1 0-.3.1-.3.2-.1.1-.1.3-.1.4l.5 2.4c-2.1.4-4.3.6-6.4.6-2.1 0-4.2-.2-6.3-.6l1.1-4c.2-.6.9-.7 1.2-.7H14c.1 0 .3-.1.4-.2.1-.1.2-.2.2-.4 0-.1-.1-.3-.2-.4-.1-.1-.2-.2-.4-.2H7.9c-.5.2-.9.3-1.3.5-.4.3-.7.7-.9 1.1L4.5 9.2c-.5.1-1 .3-1.4.8-.4.4-.6 1-.6 1.5v7.9c0 .1 0 .3.1.4 0 .1.1.2.2.3.1.1.2.2.3.2.1 0 .3.1.4.1h2.1c.2 0 .3 0 .5-.1.1-.1.3-.2.4-.3.1-.1.1-.2.2-.3v-2.4h10.5v2.1c0 .1 0 .3.1.4.1.1.2.3.3.4.1.1.2.2.4.2.1 0 .3.1.4.1h2c.2 0 .3 0 .5-.1.1-.1.3-.2.4-.3.1-.1.1-.2.2-.3v-8.2c0-.6-.2-1.1-.6-1.5-.4-.6-1-.9-1.5-.9zM3.6 11.5c0-.2 0-.3.1-.5s.2-.3.3-.4c.1-.1.3-.2.4-.3.2-.1.3-.1.5-.1 2.3.6 4.6.8 6.9.7 2.4 0 4.8-.2 7.1-.7.2 0 .4 0 .5.1.2.1.3.1.4.3.1.1.2.3.3.4.1.2.1.3.1.5v4.7H3.6v-4.7zm2.1 7.8H3.6v-2.1h2.1v2.1zm14.7 0h-2.1v-2.1h2.1v2.1z" />
    <path d="M16.7 15.1h.2c.1 0 .1-.1.2-.1 0 0 .1-.1.1-.2v-1.2c0-.5 0-.5.5-.5h1c.1 0 .3-.1.4-.2.1-.1.2-.2.2-.4 0-.1-.1-.3-.2-.4 0 0-.1-.1-.3-.1h-1c-.2 0-.4 0-.6.1-.2.1-.4.2-.5.3-.2.2-.3.3-.3.5-.1.2-.1.4-.1.6v1.2c0 .1.1.1.1.2 0 0 .1.1.2.1 0 .1 0 .1.1.1zm-10-1.5v1c0 .1.1.3.2.4.1.1.2.2.4.2.1 0 .3-.1.4-.2.1-.1.2-.2.2-.4v-1c0-.2 0-.4-.1-.6-.1-.2-.2-.4-.3-.5-.2-.2-.3-.3-.5-.3-.3-.2-.6-.2-.8-.2h-1c-.1 0-.3.1-.4.2-.1.1-.2.2-.2.4 0 .1.1.3.2.4.1 0 .2.1.4.1h1c.5 0 .5 0 .5.5z" />
  </svg>
);

const ForwardRef = forwardRef(KijijiCarIcon);
export default ForwardRef;
