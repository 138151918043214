import classNames from "classnames";

type Props<TLeft extends string, TRight extends string> = {
  leftLabel: string;
  rightLabel?: string;
  leftValue: TLeft;
  rightValue?: TRight;
  value?: TLeft | TRight;
  onChange?(selected: TLeft | TRight): void;
  rounded?: "sm" | "md" | "lg" | "xl" | "full";
  locale?: string;
};

export const SlideToggle = <TLeft extends string, TRight extends string>({
  leftLabel,
  rightLabel,
  leftValue,
  rightValue,
  value,
  onChange,
  rounded = "full",
  locale = "en",
}: Props<TLeft, TRight>) => {
  const isSingleToggle = !rightLabel || !rightValue;

  if (isSingleToggle) {
    return (
      <div
        className={classNames(
          "relative flex h-10 w-1/2 items-center overflow-hidden bg-gray-50 px-1 text-center lg:mb-6",
          {
            "rounded-full": rounded === "full",
            "rounded-lg": rounded === "lg",
            "rounded-md": rounded === "md",
            "rounded-sm": rounded === "sm",
            "rounded-xl": rounded === "xl",
          }
        )}
      >
        <div
          className={classNames(
            "relative block h-8 w-full select-none py-1 before:absolute before:left-0 before:top-0 before:h-full before:w-full before:bg-white before:shadow-[0_3px_14px_rgba(41,62,221,0.17)] before:duration-200",
            {
              "before:rounded-full": rounded === "full",
              "before:rounded-xl": rounded === "xl",
              "before:rounded-lg": rounded === "lg",
              "before:rounded-md": rounded === "md",
              "before:rounded-sm": rounded === "sm",
            }
          )}
        />

        <span className="text-md font-semibold pointer-events-none absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center text-primary-500">
          {leftLabel}
        </span>
      </div>
    );
  }
  return (
    <div
      className={classNames(
        "relative mb-6 h-full min-h-[2rem] w-full items-center overflow-hidden bg-secondary border-secondary border py-1 pl-1 pr-[50%] text-center sm:flex",
        {
          "rounded-full": rounded === "full",
          "rounded-lg": rounded === "lg",
          "rounded-md": rounded === "md",
          "rounded-sm": rounded === "sm",
          "rounded-xl": rounded === "xl",
        }
      )}
    >
      <input
        className="peer absolute bottom-0 left-0 top-0 z-0 h-full w-full cursor-pointer opacity-0"
        type="checkbox"
        checked={value === rightValue}
        onChange={(e) => onChange?.(e.target.checked ? rightValue : leftValue)}
        aria-label={`Click to toggle between ${leftLabel} and ${rightLabel}`}
      />
      <div
        className={classNames(
          "pointer-events-none relative h-full min-h-[1.75rem] flex-1 select-none before:absolute before:left-0 before:top-0 before:h-full before:w-full before:translate-x-0 before:bg-white before:shadow-[0_3px_14px_rgba(41,62,221,0.17)] before:duration-200 peer-checked:before:translate-x-full peer-checked:before:duration-200 peer-checked:before:ease-linear",
          {
            "before:rounded-full": rounded === "full",
            "before:rounded-xl": rounded === "xl",
            "before:rounded-lg": rounded === "lg",
            "before:rounded-md": rounded === "md",
            "before:rounded-sm": rounded === "sm",
            "p-4": locale === "en",
            "px-4 py-6 md:p-4": locale !== "en",
          }
        )}
      />

      <span className="text-md font-medium pointer-events-none absolute bottom-0 left-0 top-0 flex w-1/2 items-center justify-center p-2 text-primary-500 peer-checked:text-secondary">
        {leftLabel}
      </span>

      <span className="text-md font-medium pointer-events-none absolute bottom-0 right-0 top-0 flex w-1/2 items-center justify-center text-secondary peer-checked:text-primary-500">
        {rightLabel}
      </span>
    </div>
  );
};
