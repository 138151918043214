import classNames from "classnames";
import { ChangeEvent, ReactNode } from "react";

type Props<T> = {
  id: string;
  children: ReactNode;
  className?: string;
  labelDisplay?: "flex" | "inline-block";
  objectValue: T;
  onChange: (event: ChangeEvent<HTMLInputElement>, objectValue: T) => void;
  isChecked: boolean;
  name: string;
  value: string;
  inputRef?: (ref: HTMLInputElement) => void;
  colorStyles?: string;
};

export const DivRadioButton = <T extends object>({
  id,
  children,
  className,
  objectValue,
  onChange,
  isChecked,
  labelDisplay = "flex",
  name,
  value,
  inputRef,
  colorStyles,
}: Props<T>) => {
  const defaultColorStyles = classNames(
    "border-secondary relative block shadow-input-box bg-white cursor-pointer rounded-lg border px-6 py-4 focus:outline-none hover:border-brand sm:flex sm:justify-center",
    "kijiji:border-gray-100 kijiji:hover:border-info",
    "autocan:hover:border-primary-500",
    {
      "hover:border-brand kijiji:hover:border-info autocan:hover:border-primary-500 ring-secondary kijiji:ring-info autocan:ring-primary-500 ring-1 ring-offset-0":
        isChecked,
    }
  );

  return (
    <div className="relative flex h-full flex-col">
      <input
        id={id}
        name={name}
        type="radio"
        className="peer absolute bottom-0 left-0 opacity-0"
        onChange={(event) => onChange(event, objectValue)}
        value={value}
        checked={isChecked}
        ref={inputRef}
      />
      <label
        htmlFor={id}
        className={classNames(
          "h-full w-full cursor-pointer select-none content-center items-center justify-center rounded py-3 text-center peer-focus:border-brand md:hover:border-brand",
          "kijiji:peer-focus:border-info md:kijiji:hover:border-info",
          "autocan:peer-focus:border-primary-500 md:autocan:hover:border-primary-500",
          "throttle:peer-checked:border-primary-500 throttle:peer-checked:ring-primary-500 throttle:peer-checked:text-secondary throttle:peer-checked:[&>svg]:text-secondary md:throttle:hover:border-primary-500 throttle:peer-focus:border-primary-500 throttle:border-gray-50",
          colorStyles || defaultColorStyles,
          className,
          labelDisplay
        )}
      >
        {children}
      </label>
    </div>
  );
};
