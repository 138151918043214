import { SVGProps, Ref, forwardRef } from "react";

const ThumbDownIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="none"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Thumb Down Icon"
    {...props}
  >
    <title id="Thumb Down Icon">Thumb Down Icon</title>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M17 2v11m5-3.2V5.2c0-1.12 0-1.68-.218-2.108a2 2 0 0 0-.874-.874C20.48 2 19.92 2 18.8 2H8.118c-1.461 0-2.192 0-2.782.267A3 3 0 0 0 4.06 3.361c-.354.542-.465 1.265-.687 2.71l-.523 3.4c-.293 1.904-.44 2.857-.157 3.598a3 3 0 0 0 1.32 1.539c.69.392 1.653.392 3.58.392H8.4c.56 0 .84 0 1.054.109a1 1 0 0 1 .437.437C10 15.76 10 16.04 10 16.6v2.934A2.466 2.466 0 0 0 12.466 22a.819.819 0 0 0 .75-.488l3.362-7.562c.152-.344.229-.516.35-.642a1 1 0 0 1 .383-.249c.164-.059.352-.059.729-.059h.76c1.12 0 1.68 0 2.108-.218a2 2 0 0 0 .874-.874C22 11.48 22 10.92 22 9.8Z"
    />
  </svg>
);

const ForwardRef = forwardRef(ThumbDownIcon);
export default ForwardRef;
