import { SVGProps, Ref, forwardRef } from "react";

const BluetoothIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="none"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Bluetooth Icon"
    {...props}
  >
    <title id="Bluetooth Icon">Bluetooth Icon</title>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m6 7 12 10-6 5V2l6 5L6 17"
    />
  </svg>
);

const ForwardRef = forwardRef(BluetoothIcon);
export default ForwardRef;
