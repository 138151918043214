import { SVGProps, Ref, forwardRef } from "react";

const KijijiDropOffIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlSpace="preserve"
    id="Layer_1"
    x={0}
    y={0}
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    {...props}
  >
    <path
      d="M11 16.5c-.4 0-.8.3-.8.7 0 .4.3.7.8.7.4 0 .8-.3.8-.7 0-.3-.4-.7-.8-.7m-1.3.8c0-.7.6-1.4 1.4-1.4s1.4.6 1.4 1.4c0 .7-.6 1.4-1.4 1.4s-1.4-.7-1.4-1.4m8.4-.8c-.4 0-.8.3-.8.7 0 .4.3.7.8.7.4 0 .8-.3.8-.7 0-.3-.4-.7-.8-.7m-1.4.8c0-.7.6-1.4 1.4-1.4s1.4.6 1.4 1.4c0 .7-.6 1.4-1.4 1.4s-1.4-.7-1.4-1.4"
      style={{
        fill: "currentColor",
        fillRule: "evenodd",
        clipRule: "evenodd",
      }}
    />
    <defs>
      <path id="SVGID_1_" d="M6.8 9.7h15.6v12.5H6.8V9.7" />
    </defs>
    <clipPath id="SVGID_00000045590259808703759850000008260713280143566979_">
      <use
        xlinkHref="#SVGID_1_"
        style={{
          overflow: "visible",
        }}
      />
    </clipPath>
    <path
      d="M9.5 20.8V20h1.6v.7H9.5m8.6.1V20h1.5v.7h-1.5m-8.8-1.3c-.1 0-.2 0-.3-.1-.1-.1-.1-.2-.1-.3v-3.1c0-.3.1-.5.3-.7.2-.2.4-.3.7-.3 1.5.3 2.9.5 4.4.5h.5c1.5 0 3.1-.2 4.6-.5h.1c.3 0 .5.1.7.3.2.2.3.4.2.7V19c0 .1 0 .2-.1.3-.1.1-.2.1-.3.1H9.3m.4-5.1H8.1v-1.2h1.1c.1 0 .1.1.2.1.1.1.1.1.1.2v.9m9.9 0v-.6c0-.2.1-.3.2-.5.1-.1.3-.2.5-.2h.9v1.2h-1.6m-5 .6c-1.3 0-2.7-.1-4-.4l.7-2.7c.1-.2.2-.3.3-.4.1-.1.3-.2.5-.2h5.3c.2 0 .4.1.5.2.1.1.2.3.3.4l.8 2.7c-1.3.2-2.7.4-4.1.4h-.3m2.7-4.3h-5.3c-.3 0-.6.1-.9.3-.3.2-.4.5-.5.8l-.3 1.4-.1-.2c-.1-.1-.3-.2-.4-.3-.2-.1-.3-.1-.5-.1h-.9c-.1 0-.2 0-.3.1-.1 0-.2.1-.2.2-.1.1-.1.1-.1.2v1.6c0 .1.1.2.1.2.1.1.1.1.2.1h.7c-.2.3-.4.6-.4 1V19c0 .2.1.4.2.6.1.2.3.3.5.4v1.1c0 .1.1.2.1.2l.2.2c.1 0 .2.1.3.1H11c.1 0 .2 0 .3-.1.1 0 .2-.1.2-.2.1-.1.1-.1.1-.2V20h5.8v1c0 .1.1.2.1.2l.2.2c.1 0 .2.1.3.1h1.5c.1 0 .2 0 .3-.1.1 0 .2-.1.2-.2.1-.1.1-.1.1-.2v-1c.2-.1.4-.2.5-.4.1-.2.2-.4.2-.6v-3.1c0-.4-.1-.7-.3-1h.7c.1 0 .2-.1.2-.1.1-.1.1-.1.1-.2V13c0-.1-.1-.2-.1-.2l-.2-.2c-.1 0-.2-.1-.3-.1H20c-.2 0-.4.1-.6.2-.2.1-.3.3-.5.4l-.4-1.5c-.1-.3-.3-.6-.5-.7-.3-.3-.6-.4-.9-.4"
      style={{
        clipPath:
          "url(#SVGID_00000045590259808703759850000008260713280143566979_)",
        fill: "currentColor",
      }}
    />
    <path
      d="M2.8 7.1v1.8c0 .1 0 .2.1.2s.2.1.3.1.2 0 .2-.1.1-.2.1-.2V7.3h.4c.3 0 .7-.1 1.1-.3.3-.2.6-.5.8-.8.2.4.5.6.8.8.3.2.7.3 1.1.3.4 0 .8-.1 1.1-.3.3-.2.6-.5.8-.8.2.3.5.6.8.8.3.2.7.3 1.1.3s.8-.1 1.1-.3c.3-.2.6-.5.8-.8.3.4.6.6.9.8.3.2.7.3 1.1.3.4 0 .8-.1 1.1-.3.3-.2.6-.5.8-.8.2.3.5.6.8.8s.7.3 1.1.3h.4l.1 4c0 .1 0 .2.1.2.1.1.1.1.2.1s.2 0 .2-.1c.1-.1.1-.1.1-.2l-.1-4.2c.4-.2.7-.4.9-.8.2-.3.3-.7.4-1.1V5l-1.2-3-.1-.1-.2-.1H3.2l-.2.1-.1.1-1.3 3.1v.2c0 .3.2.7.4 1 .2.3.5.6.8.8zm.6-4.7h16.4L21 5.2c0 .4-.2.8-.5 1.1-.3.3-.7.4-1.1.4-.4 0-.8-.2-1.1-.5-.3-.3-.5-.7-.5-1.1 0-.1 0-.2-.1-.2-.1-.1-.1-.1-.2-.1s-.2 0-.2.1c-.1.1-.1.1-.1.2 0 .4-.2.8-.5 1.1-.3.3-.7.4-1.1.4-.4 0-.8-.2-1.1-.4-.5-.2-.6-.6-.7-1 0-.1 0-.2-.1-.2-.1-.1-.1-.1-.2-.1s-.2 0-.2.1c-.1.1-.1.1-.1.2 0 .4-.2.8-.5 1.1-.3.3-.7.4-1.1.4-.4 0-.8-.2-1.1-.4-.3-.3-.5-.7-.5-1.1 0-.1-.1-.2-.1-.2-.1-.1-.2-.1-.2-.1-.1 0-.2 0-.2.1-.1 0-.2.1-.2.2 0 .4-.2.8-.5 1.1-.3.2-.7.4-1.1.4s-.8-.2-1.1-.4c-.3-.3-.5-.7-.5-1.1 0-.1 0-.2-.1-.2-.1-.1-.1-.1-.2-.1s-.2 0-.2.1c-.1 0-.1.1-.1.2 0 .4-.2.8-.5 1-.3.3-.7.5-1.1.5-.4 0-.8-.1-1.1-.4-.4-.3-.5-.7-.6-1.1l1.2-2.8z"
      className="st2"
    />
    <path
      d="M5.7 20.8H3.5V8.9c0-.1-.3-.1-.3-.2-.1-.1.1 0 0 0 0 0-.3 0-.3.1v12.4l.1.1v.1h2.6c.1 0 .2 0 .2-.1.1 0 .2-.1.2-.2s0-.2-.1-.2c-.1-.1-.2-.1-.2-.1z"
      className="st2"
    />
  </svg>
);

const ForwardRef = forwardRef(KijijiDropOffIcon);
export default ForwardRef;
