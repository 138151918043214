import { SVGProps, Ref, forwardRef } from "react";

const TimeExpiringIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 23 20"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    {...props}
  >
    <g clipPath="url(#a)">
      <path d="M12.903 5.534H11.43v5.44h1.473v-5.44Z" />
      <path d="M15.996 10.557h-4.568v1.318h4.568v-1.318ZM12.567 20v-2.53c4.051 0 7.348-3.352 7.348-7.47h2.49c0 5.514-4.413 10-9.838 10Zm9.838-10h-2.49c0-4.118-3.297-7.47-7.348-7.47-4.052 0-7.349 3.352-7.349 7.47h-2.49c0-5.515 4.413-10 9.839-10 5.425 0 9.838 4.485 9.838 10Z" />
      <path d="M7.49 9.936H.5l3.495 4.243 3.494-4.243Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path d="M0 0h21.905v20H0z" transform="translate(.5)" />
      </clipPath>
    </defs>
  </svg>
);

const ForwardRef = forwardRef(TimeExpiringIcon);
export default ForwardRef;
