import { SVGProps, Ref, forwardRef } from "react";

const AimIcon = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 22 22"
    fill="none"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Aim Icon"
    {...props}
  >
    <title id="Aim Icon">Aim Icon</title>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M21 11h-4M5 11H1m10-6V1m0 20v-4m8-6a8 8 0 1 1-16 0 8 8 0 0 1 16 0Zm-5 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
    />
  </svg>
);

const ForwardRef = forwardRef(AimIcon);
export default ForwardRef;
