import { SVGProps, Ref, forwardRef } from "react";

const FormatBoldIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="none"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Format Bold Icon"
    {...props}
  >
    <title id="Format Bold Icon">Format Bold Icon</title>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M6 12h8a4 4 0 0 0 0-8H6v8Zm0 0h9a4 4 0 0 1 0 8H6v-8Z"
    />
  </svg>
);

const ForwardRef = forwardRef(FormatBoldIcon);
export default ForwardRef;
