import { SVGProps, Ref, forwardRef } from "react";

const RRCoverageGapIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Coverage Gap Icon"
    {...props}
  >
    <title id="Coverage Gap Icon">Coverage Gap Icon</title>
    <path
      d="M12 20c3.9 0 7-3.1 7-7s-3.1-7-7-7-7 3.1-7 7 3.1 7 7 7z"
      style={{
        fill: "#d5f8ff",
      }}
    />
    <path
      d="m15.8 9.4-4.9 3c-.7-.2-1.4 0-2 .5-.9.8-.9 2.1-.1 3 .8.9 2.1.9 3 .1.5-.5.8-1.2.6-1.9l3.4-4.7z"
      style={{
        fill: "#00cfeb",
      }}
    />
    <path
      d="M7 18c-1.2-1.3-2-3-2-4.9 0-3.9 3.1-7 7-7s7 3.1 7 7c0 1.9-.8 3.6-2 4.9l1.9 1.9c1.7-1.7 2.8-4.1 2.8-6.8 0-5.3-4.3-9.7-9.7-9.7-5.3 0-9.7 4.3-9.7 9.7 0 2.6 1.1 5 2.8 6.8L7 18z"
      style={{
        fill: "#293edc",
      }}
    />
  </svg>
);

const ForwardRef = forwardRef(RRCoverageGapIcon);
export default ForwardRef;
