import { SVGProps, Ref, forwardRef } from "react";

const TradeInIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-label="Trade-In Icon"
    ref={ref}
    {...props}
  >
    <path
      d="M20.6987 19.0954C20.3848 19.0954 20.0996 18.8761 20.0215 18.5487L18.9556 14.0753C18.9045 13.8609 18.9524 13.6342 19.0853 13.4608C19.2183 13.2875 19.4214 13.1842 19.6363 13.1876L21.1322 13.1942C22.7709 13.2024 24.2168 14.2797 24.7333 15.8761L25.4873 18.055C25.6164 18.4281 25.4268 18.8382 25.0638 18.9709C24.7006 19.1037 24.3017 18.9087 24.1726 18.5356L23.416 16.3494C23.4142 16.3443 23.4125 16.339 23.4108 16.3339C23.0848 15.319 22.1666 14.6336 21.1259 14.6285L20.5234 14.6258L21.3769 18.2074C21.4686 18.5921 21.2395 18.9803 20.8652 19.0746C20.8095 19.0887 20.7536 19.0954 20.6987 19.0954Z"
      fill="#6E8DC2"
    />
    <path
      d="M14.0321 19.0131C13.721 19.0131 13.4375 18.7978 13.3567 18.4739L12.4439 14.8123C12.3482 14.4287 12.5733 14.0379 12.9465 13.9396C13.3197 13.8413 13.6999 14.0726 13.7956 14.4563L14.7084 18.1178C14.8041 18.5015 14.579 18.8922 14.2058 18.9905C14.1477 19.0058 14.0894 19.0131 14.0321 19.0131Z"
      fill="#6E8DC2"
    />
    <path
      d="M31.868 27.2808H29.871V25.8465H31.868C32.4087 25.8465 32.8487 25.3943 32.8487 24.8385V22.3664C32.8487 21.3446 32.1383 20.4722 31.1595 20.292L26.2725 19.394H16.2659C13.5329 19.394 12.3755 18.7464 10.7737 17.8502C10.6729 17.7938 10.5701 17.7362 10.4647 17.6777C9.51636 17.1507 8.41863 17.0039 7.37392 17.2643L4.65577 17.9417C3.778 18.1603 3.16492 18.9614 3.16492 19.8898V24.8385C3.16492 25.3943 3.60485 25.8465 4.14563 25.8465H6.33601V27.2808H4.14563C2.83542 27.2808 1.76953 26.1852 1.76953 24.8385V19.8898C1.76953 18.2972 2.82121 16.9229 4.32703 16.5477L7.04518 15.8703C8.42586 15.5264 9.87592 15.72 11.1283 16.416C11.235 16.4752 11.3389 16.5335 11.4409 16.5904C12.9669 17.4444 13.888 17.9597 16.2659 17.9597H26.3344C26.3755 17.9597 26.4166 17.9635 26.4572 17.9709L31.4053 18.8802C33.0503 19.1831 34.2441 20.6492 34.2441 22.3664V24.8385C34.2441 26.1852 33.1781 27.2808 31.868 27.2808Z"
      fill="#2F4575"
    />
    <path
      d="M24.4532 27.2809H11.9164C11.5311 27.2809 11.2188 26.9599 11.2188 26.5638C11.2188 26.1678 11.5311 25.8467 11.9164 25.8467H24.4532C24.8385 25.8467 25.1509 26.1678 25.1509 26.5638C25.1509 26.9599 24.8385 27.2809 24.4532 27.2809Z"
      fill="#2F4575"
    />
    <path
      d="M9.0445 30.0639C7.16656 30.0639 5.63867 28.4935 5.63867 26.5632C5.63867 24.6329 7.1665 23.0625 9.0445 23.0625C10.9225 23.0625 12.4503 24.6329 12.4503 26.5632C12.4503 28.4935 10.9225 30.0639 9.0445 30.0639ZM9.0445 24.4968C7.93593 24.4968 7.03406 25.4238 7.03406 26.5632C7.03406 27.7027 7.93593 28.6297 9.0445 28.6297C10.1531 28.6297 11.0549 27.7027 11.0549 26.5632C11.0549 25.4238 10.1531 24.4968 9.0445 24.4968Z"
      fill="#6E8DC2"
    />
    <path
      d="M27.1616 30.0649C25.2837 30.0649 23.7559 28.4945 23.7559 26.5642C23.7559 24.6339 25.2837 23.0635 27.1616 23.0635C29.0396 23.0635 30.5675 24.6339 30.5675 26.5642C30.5675 28.4945 29.0396 30.0649 27.1616 30.0649ZM27.1616 24.4977C26.0531 24.4977 25.1512 25.4247 25.1512 26.5642C25.1512 27.7036 26.0531 28.6306 27.1616 28.6306C28.2701 28.6306 29.1721 27.7036 29.1721 26.5642C29.1721 25.4247 28.2702 24.4977 27.1616 24.4977Z"
      fill="#6E8DC2"
    />
    <path
      d="M19.0384 23.1052H16.975C16.5897 23.1052 16.2773 22.7841 16.2773 22.388C16.2773 21.992 16.5897 21.6709 16.975 21.6709H19.0384C19.4237 21.6709 19.7361 21.992 19.7361 22.388C19.7361 22.7841 19.4237 23.1052 19.0384 23.1052Z"
      fill="#2F4575"
    />
    <path
      d="M9.73623 9.16545C9.73623 9.56183 9.42417 9.88258 9.03853 9.88258H8.25331V11.3527C8.25331 11.7491 7.94125 12.0698 7.55561 12.0698C7.17061 12.0698 6.85792 11.7491 6.85792 11.3527V9.88258H6.07269C5.68706 9.88258 5.375 9.56183 5.375 9.16545C5.375 8.76972 5.68706 8.44832 6.07269 8.44832H6.85792V6.97885C6.85792 6.58247 7.17061 6.26172 7.55561 6.26172C7.94125 6.26172 8.25331 6.58247 8.25331 6.97885V8.44832H9.03853C9.42417 8.44832 9.73623 8.76972 9.73623 9.16545Z"
      fill="#6E8DC2"
    />
    <path
      d="M31.5311 12.0824C31.5311 12.4788 31.2191 12.7996 30.8335 12.7996H30.0482V14.2697C30.0482 14.6661 29.7362 14.9868 29.3505 14.9868C28.9655 14.9868 28.6528 14.6661 28.6528 14.2697V12.7996H27.8676C27.482 12.7996 27.1699 12.4788 27.1699 12.0824C27.1699 11.6867 27.482 11.3653 27.8676 11.3653H28.6528V9.89584C28.6528 9.49946 28.9655 9.17871 29.3505 9.17871C29.7362 9.17871 30.0482 9.49946 30.0482 9.89584V11.3653H30.8335C31.2191 11.3653 31.5311 11.6867 31.5311 12.0824Z"
      fill="#6E8DC2"
    />
    <path
      d="M12.5862 7.3646C12.1027 7.37685 11.7449 6.82851 11.9418 6.37365C12.1702 5.78828 13.0019 5.78815 13.23 6.37365C13.4272 6.82792 13.0694 7.37685 12.5862 7.3646Z"
      fill="#6E8DC2"
    />
    <path
      d="M24.9009 9.88413C24.4175 9.89645 24.059 9.34778 24.2565 8.89318C24.4847 8.30787 25.3165 8.30761 25.5446 8.89318C25.7419 9.34739 25.3841 9.89625 24.9009 9.88413Z"
      fill="#6E8DC2"
    />
  </svg>
);

const ForwardRef = forwardRef(TradeInIcon);
export default ForwardRef;
