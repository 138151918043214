import { SVGProps, Ref, forwardRef } from "react";

const FlatPhoneRotateIconIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 92 86"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Flat Phone Rotate Icon"
    {...props}
  >
    <path
      d="M60.5867 0.382446H31.4256C24.591 0.382446 19.0432 5.92023 19.0332 12.7548V73.3751C19.0432 80.2097 24.586 85.7475 31.4256 85.7475H60.5867C67.4163 85.7475 72.949 80.2097 72.949 73.3851V12.7548C72.959 5.92524 67.4263 0.382446 60.5967 0.382446C60.5967 0.382446 60.5917 0.382446 60.5867 0.382446ZM45.9911 80.8055C43.5076 80.8055 41.4998 78.7927 41.4998 76.3142C41.4998 73.8357 43.5126 71.8229 45.9911 71.8229C48.4696 71.8229 50.4824 73.8357 50.4824 76.3142C50.4824 78.7927 48.4696 80.8055 45.9911 80.8055Z"
      fill="#293EDC"
    />
    <path
      d="M67.5274 66.4503L24.4668 66.5004V15.2383L67.5274 15.1882V66.4503Z"
      fill="white"
    />
    <path
      d="M38.1757 51.1889C17.1512 49.9722 5.9504 44.4444 5.9504 40.9245C5.9504 38.9467 10.0512 35.7973 19.0388 33.454V28.3718C18.8385 28.3718 18.6332 28.3868 18.4279 28.4369C6.99186 31.306 0.943359 35.622 0.943359 40.9195C0.943359 46.2169 7.2272 49.6818 12.4996 51.6045C19.0338 53.9879 28.0465 55.6101 37.8903 56.1809C37.9404 56.1809 37.9905 56.1809 38.0355 56.1809C39.3524 56.1809 40.4539 55.1545 40.534 53.8226C40.6142 52.4407 39.5577 51.259 38.1807 51.1789L38.1757 51.1889Z"
      fill="#00CFEB"
    />
    <path
      d="M73.5593 28.4369C73.3541 28.3868 73.1538 28.3718 72.9535 28.3718V33.454C81.9361 35.7973 86.0419 38.9467 86.0419 40.9245C86.0419 44.4444 74.8412 49.9722 53.8166 51.1889C52.4346 51.269 51.3832 52.4507 51.4633 53.8326C51.5384 55.1645 52.6449 56.191 53.9618 56.191C54.0119 56.191 54.0619 56.191 54.107 56.191C63.9458 55.6201 72.9635 53.9979 79.4977 51.6145C89.0461 48.1296 91.054 43.8786 91.054 40.9295C91.054 35.627 85.0054 31.311 73.5694 28.4469L73.5593 28.4369Z"
      fill="#00CFEB"
    />
    <path
      d="M34.3008 48.1246L46.7683 53.6124L34.3008 59.1051V48.1246Z"
      fill="#00CFEB"
    />
  </svg>
);

const ForwardRef = forwardRef(FlatPhoneRotateIconIcon);

export default ForwardRef;
