import { SVGProps, Ref, forwardRef } from "react";

const MoneyIcon = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="none"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Payment Icon"
    {...props}
  >
    <title id="Payment Icon">Money Icon</title>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M7.5 15.428a3 3 0 0 0 3 3h2.786a3.214 3.214 0 1 0 0-6.428h-2.572a3.214 3.214 0 0 1 0-6.429H13.5a3 3 0 0 1 3 3M12 3.643V5.57m0 12.857v1.93"
    />
  </svg>
);

const ForwardRef = forwardRef(MoneyIcon);
export default ForwardRef;
