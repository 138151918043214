import { SVGProps, Ref, forwardRef } from "react";

const FlagOnIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Flag On Icon"
    {...props}
  >
    <title id="Flag On Icon">Flag On Icon</title>
    <path d="M8 14c-3 0-4 1-4 1V3s1-1 4-1 5 2 8 2 4-1 4-1v12s-1 1-4 1-5-2-8-2Z" />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M4 15s1-1 4-1 5 2 8 2 4-1 4-1V3s-1 1-4 1-5-2-8-2-4 1-4 1v19"
    />
  </svg>
);

const ForwardRef = forwardRef(FlagOnIcon);
export default ForwardRef;
