import { SVGProps, Ref, forwardRef } from "react";

const FormatAlignCentreIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="none"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Format Align Centre Icon"
    {...props}
  >
    <title id="Format Align Centre Icon">Format Align Centre Icon</title>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M18 10H6m15-4H3m18 8H3m15 4H6"
    />
  </svg>
);

const ForwardRef = forwardRef(FormatAlignCentreIcon);
export default ForwardRef;
