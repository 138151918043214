import { SVGProps, Ref, forwardRef } from "react";

const FlatKeysIconIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 85 86"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Flat Keys Icon"
    {...props}
  >
    <path
      d="M83.7823 54.6587L75.1507 30.6448C73.9626 27.2898 71.5314 24.5977 68.3064 23.0623C65.0864 21.5268 61.457 21.3374 58.0972 22.5288L54.8423 23.6804C54.4329 30.8891 50.7087 38.696 48.577 43.1627C47.923 44.5337 47.1792 45.7601 46.3555 46.8568L52.9952 65.5614C54.2232 69.0262 56.7543 71.6733 59.8395 73.139C62.9297 74.6096 66.5791 74.9038 70.0487 73.6774L75.66 71.6933C82.6042 69.2355 86.2485 61.5982 83.7873 54.6587H83.7823Z"
      fill="#293EDC"
    />
    <path
      d="M29.1362 46.1691C29.8601 46.5131 30.1646 47.3805 29.8201 48.1034L26.69 54.6639L21.6878 56.4337L23.7446 62.2315L17.9386 64.2855L19.9954 70.0833L14.1894 72.1372L15.9616 77.1324L13.5704 82.1426L3.89538 85.5674L0.470703 75.9011L16.7155 41.8619C17.0599 41.139 17.9286 40.8299 18.6525 41.1789L29.1362 46.1691Z"
      fill="#00CFEB"
    />
    <path
      d="M44.7872 12.0301L35.222 7.47855C23.036 1.67573 15.288 17.9077 11.9831 24.8372C7.99931 33.1824 11.1943 40.9445 20.5548 45.6156L24.6185 47.5499C33.969 52.0017 41.9815 49.5739 46.0502 41.0491C49.3601 34.1197 57.103 17.8877 44.7872 12.0251V12.0301ZM34.2435 21.9856C32.3265 21.0733 31.5128 18.7801 32.4264 16.8608C33.34 14.9415 35.6364 14.1338 37.5584 15.0461C39.4804 15.9584 40.2892 18.2517 39.3756 20.171C38.462 22.0853 36.1656 22.8979 34.2435 21.9856Z"
      fill="#293EDC"
    />
    <path
      d="M73.1722 56.0497C72.5083 55.7356 71.7345 55.6509 71.0405 55.9001L63.9016 58.4276C62.5138 58.9162 61.7 60.6212 62.1943 62.007C62.6636 63.3182 64.4608 64.1806 65.7787 63.712L72.9226 61.1845C74.3105 60.6959 75.1242 58.991 74.63 57.6051C74.3854 56.9121 73.8362 56.3638 73.1722 56.0497Z"
      fill="white"
    />
    <path
      d="M35.5355 19.6027C34.8466 19.2736 34.3524 18.5907 34.3025 17.7731C34.1377 15.0462 34.6769 12.2943 35.86 9.81664C39.714 1.73557 49.4389 -1.70425 57.5314 2.14934C65.6238 6.00293 69.0685 15.7092 65.2144 23.7952C64.1761 25.9738 62.7083 27.8433 60.8562 29.3638C59.9277 30.1215 58.5598 29.9869 57.801 29.0597C57.0421 28.1324 57.1769 26.7664 58.1055 26.0087C59.4634 24.897 60.5367 23.526 61.3005 21.9308C64.1261 16.0083 61.6001 8.88939 55.6693 6.06774C49.7385 3.2461 42.6096 5.76863 39.7839 11.6911C38.9153 13.5107 38.5209 15.5247 38.6407 17.5188C38.7156 18.7153 37.802 19.7422 36.6039 19.812C36.2245 19.837 35.86 19.7572 35.5405 19.6076L35.5355 19.6027Z"
      fill="#00CFEB"
    />
  </svg>
);

const ForwardRef = forwardRef(FlatKeysIconIcon);

export default ForwardRef;
