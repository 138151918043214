import { SVGProps, Ref, forwardRef } from "react";

const ChevronRightIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="none"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Chevron Right Icon"
    {...props}
  >
    <title id="Chevron Right Icon">Chevron Right Icon</title>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m9 18 6-6-6-6"
    />
  </svg>
);

const ForwardRef = forwardRef(ChevronRightIcon);

export default ForwardRef;
