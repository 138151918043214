import { SVGProps, Ref, forwardRef } from "react";

const CartIcon = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 22 22"
    fill="none"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Cart Icon"
    {...props}
  >
    <title id="Cart Icon">Cart Icon</title>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M4 13h13.136c1.013 0 1.52 0 1.922-.189a2 2 0 0 0 .858-.768c.232-.38.288-.883.4-1.89l.585-5.265c.035-.307.052-.46.002-.58a.5.5 0 0 0-.22-.246C20.57 4 20.416 4 20.106 4H3.5M1 1h1.248c.265 0 .397 0 .5.05a.5.5 0 0 1 .217.204c.057.1.065.232.082.496l.906 14.5c.017.264.025.396.082.496a.5.5 0 0 0 .216.204c.104.05.236.05.5.05H18M6.5 20.5h.01m8.99 0h.01M7 20.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Zm9 0a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Z"
    />
  </svg>
);

const ForwardRef = forwardRef(CartIcon);
export default ForwardRef;
