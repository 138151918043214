import { SVGProps, Ref, forwardRef } from "react";

const ThrottleMapPinIconIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    width="31"
    height="39"
    viewBox="0 0 31 39"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    aria-label="Throttle Map Pin Icon"
    {...props}
  >
    <path
      d="M15.3333 38.3333C19.1667 30.6667 30.6667 25.7184 30.6667 15.3333C30.6667 6.86497 23.8017 0 15.3333 0C6.86497 0 0 6.86497 0 15.3333C0 25.7184 11.5 30.6667 15.3333 38.3333Z"
      fill="url(#paint0_linear_14313_8570)"
    />
    <path
      d="M15.3333 38.3333C19.1667 30.6667 30.6667 25.7184 30.6667 15.3333C30.6667 6.86497 23.8017 0 15.3333 0C6.86497 0 0 6.86497 0 15.3333C0 25.7184 11.5 30.6667 15.3333 38.3333Z"
      fill="#0A2E20"
      fill-opacity="0.42"
    />
    <path
      d="M13.1995 21.3611C13.1376 21.1365 10.4858 18.8277 10.2558 16.5841C10.1372 15.424 10.4198 14.2949 11.0758 13.3459L9.19346 10.5393C7.53908 12.2914 6.80059 14.5919 7.04606 16.9856C7.04606 16.9856 7.32351 19.519 9.15942 21.3611"
      fill="#EFFAF3"
    />
    <path
      d="M17.2826 21.3611C17.3445 21.1365 19.9963 18.8277 20.2263 16.5841C20.3449 15.424 20.0623 14.2949 19.4063 13.3459L21.2886 10.5393C22.943 12.2914 23.6815 14.5919 23.436 16.9856C23.436 16.9856 23.1586 19.519 21.3226 21.3611"
      fill="#EFFAF3"
    />
    <path
      d="M14.8133 8.008C13.5519 8.05353 12.3162 8.39194 11.1373 9.01494L12.6597 11.8785C14.0201 11.1675 15.6425 11.1427 16.9782 11.6663L18.9895 8.95699C17.7095 8.18082 16.3748 7.95211 14.8133 8.008Z"
      fill="#EFFAF3"
    />
    <path
      d="M20.3616 9.12494L14.4092 16.0839C14.2517 16.2155 14.2283 16.4462 14.3624 16.5528L15.1619 17.152C15.296 17.2586 15.5148 17.1824 15.6057 16.9986L20.7587 9.44042C20.9047 9.14798 20.6117 8.91457 20.3616 9.12388L20.3616 9.12494Z"
      fill="#2A9F66"
    />
    <path
      d="M15.006 17.8304C15.8924 17.845 16.6229 17.1358 16.6374 16.2463C16.6519 15.3568 15.945 14.624 15.0585 14.6094C14.1721 14.5948 13.4417 15.3041 13.4271 16.1935C13.4126 17.083 14.1195 17.8159 15.006 17.8304Z"
      fill="#2A9F66"
    />
    <defs>
      <linearGradient
        id="paint0_linear_14313_8570"
        x1="-0.000477572"
        y1="38.3331"
        x2="37.398"
        y2="8.4142"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#144D35" />
        <stop offset="1" stop-color="#19754D" />
      </linearGradient>
    </defs>
  </svg>
);

const ForwardRef = forwardRef(ThrottleMapPinIconIcon);
export default ForwardRef;
