import classNames from "classnames";
import { BaseInputProps } from "./types";
import { Hint, Label, Feedback } from "../index";

export const BaseInput = ({
  id,
  label,
  hint,
  status,
  feedback,
  subLabel,
  leftIcon,
  leftIconClickable,
  rightIcon,
  rightIconClickable,
  labelTextStyle,
  className,
  isOptional,
  hidden,
  children,
}: BaseInputProps) => {
  return hidden ? null : (
    <div className={className}>
      <div className="flex flex-row justify-between">
        <div className="flex-initial text-text-light-100 text-sm font-medium">
          {label && (
            <Label
              id={id}
              label={label}
              textStyle={labelTextStyle}
              showOptional={isOptional}
            />
          )}
        </div>
      </div>
      <div className="relative py-1">
        {leftIcon && (
          <span
            className={classNames(
              "absolute inset-y-2 left-0 flex items-center pl-2 h-8 w-8 text-text-light-500",
              { "pointer-events-none": !leftIconClickable }
            )}
          >
            {leftIcon}
          </span>
        )}

        {/* this is the HTML input element */}
        {children}

        {rightIcon && (
          <span
            className={classNames(
              "absolute inset-y-2 right-0 flex items-center pr-1.5 h-8 w-8 text-text-light-200",
              { "pointer-events-none": !rightIconClickable }
            )}
          >
            {rightIcon}
          </span>
        )}
      </div>
      <div className="flex items-start">
        {hint && <Hint hint={hint} align="left" />}
      </div>
      {feedback && <Feedback status={status}>{feedback}</Feedback>}
      {subLabel}
    </div>
  );
};

export default BaseInput;
