import { SVGProps, Ref, forwardRef } from "react";

const RoadwisePriceTagIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 93 93"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    {...props}
  >
    <title id="RoadwisePriceTagIcon">RoadwisePriceTagIcon</title>
    <path
      d="M64.8296 24.9227L38.0454 22.5548C36.4613 22.414 34.8993 22.9818 33.7754 24.0953L6.78325 50.9469C4.23892 53.4776 4.23892 57.584 6.78325 60.119L29.4478 82.662C31.9921 85.1926 36.1206 85.1926 38.6694 82.662L65.6659 55.8103C66.7898 54.6924 67.3562 53.1387 67.2146 51.5631L64.834 24.9227H64.8296Z"
      fill="#506C99"
    />
    <path
      d="M55.5905 38.3949C58.0783 38.3949 60.0951 36.389 60.0951 33.9145C60.0951 31.44 58.0783 29.4341 55.5905 29.4341C53.1027 29.4341 51.0859 31.44 51.0859 33.9145C51.0859 36.389 53.1027 38.3949 55.5905 38.3949Z"
      fill="white"
    />
    <path
      d="M69.1953 7.20801C59.0887 7.20801 50.8672 15.3854 50.8672 25.4377C50.8672 28.743 51.7655 31.9779 53.4646 34.7991C54.093 35.8422 55.4514 36.1811 56.5001 35.5561C57.5488 34.9311 57.8896 33.58 57.2612 32.5369C55.9736 30.4023 55.2921 27.9464 55.2921 25.4422C55.2921 17.8193 61.5269 11.6136 69.1953 11.6136C76.8637 11.6136 83.0984 17.8149 83.0984 25.4422C83.0984 33.0694 76.8637 39.2707 69.1953 39.2707C68.4829 39.2707 67.7705 39.2179 67.0757 39.1123C66.7394 39.0594 66.412 39.0947 66.1067 39.1871L66.4916 43.4738C67.3811 43.6059 68.2926 43.6719 69.1997 43.6719C79.3063 43.6719 87.5278 35.4945 87.5278 25.4422C87.5278 15.3898 79.2974 7.20801 69.1953 7.20801Z"
      fill="#A6B6D3"
    />
    <path
      d="M43.7844 53.9311C44.1119 52.0782 43.5366 49.6047 41.7313 47.8046C40.0896 46.1718 38.4214 45.9253 37.3815 46.9596C34.4522 49.8732 45.019 57.7117 39.271 63.4289C36.5629 66.1224 32.5938 66.206 28.983 63.7546L26.4918 66.2324L23.6465 63.4025L26.0537 61.0082C23.3987 57.879 22.9076 54.7761 23.4253 52.2455L28.1334 52.4083C27.7794 54.939 28.5715 57.2012 30.4034 59.0233C32.3194 60.929 34.1513 61.2283 35.4124 59.9739C38.5851 56.8183 27.9431 49.059 33.6071 43.4255C36.2089 40.8376 40.1737 40.9212 43.6782 43.4783L46.1119 41.0576L48.9572 43.8876L46.4925 46.339C48.2979 48.8433 48.8997 51.6205 48.4616 53.9619L43.78 53.9355L43.7844 53.9311Z"
      fill="white"
    />
  </svg>
);

const ForwardRef = forwardRef(RoadwisePriceTagIcon);
export default ForwardRef;
