import { SVGProps, Ref, forwardRef } from "react";

const KijijiSendMoneyIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    {...props}
  >
    <path d="M22.1 7.1c0-.4-.2-.9-.5-1.2-.3-.3-.7-.5-1.2-.5H2.3c-.1 0-.2 0-.3.1-.1.1-.1.2-.1.3 0 .1 0 .2.1.3.1.1.2.1.3.1h18.2l-3.4 3.4-.1.1v.4l.1.1.1.1h.4c.1 0 .1-.1.1-.1l3.4-3.4c0 .1.1.2.1.3v9.8c0 .2-.1.4-.2.6-.2.2-.4.2-.6.2H6.9c-.2 0-.4-.1-.6-.2-.2-.2-.3-.4-.3-.6v-3.7c0-.1 0-.2-.1-.3-.1-.1-.2-.1-.3-.1-.1 0-.2 0-.3.1-.1.1-.1.2-.1.3v3.7c0 .4.2.9.5 1.2.3.3.7.5 1.2.5h13.5c.4 0 .9-.2 1.2-.5.3-.3.5-.7.5-1.2V7.1z" />
    <path d="M6.9 8.3v-.2L6.8 8l-.1-.1H3.1c-.1 0-.3 0-.3.1-.1.1-.2.2-.2.3 0 .1 0 .2.1.3.1.1.3.1.4.1h3.6c.1 0 .1-.1.1-.1l.1-.1c-.1-.1 0-.1 0-.2zM6 10.8v-.2l-.1-.1-.1-.1H3.9c-.1 0-.2 0-.3.1-.1.1-.1.2-.1.3s0 .2.1.3c.1.1.2.1.3.1h1.9c.1 0 .1-.1.1-.1 0-.1.1-.1.1-.2v-.1zm7.5 3.6c-.2 0-.3 0-.5-.1-.1-.1-.3-.1-.4-.2s-.2-.2-.3-.4c-.1-.1-.1-.3-.1-.4h-1.1c0 .5.1 1 .5 1.4.3.4.8.6 1.3.6v.8h.8v-.8c.5 0 .9-.2 1.3-.6.3-.3.5-.8.5-1.3 0-.9-.5-1.4-1.9-1.9-.8-.3-1.2-.5-1.2-1 0-.1 0-.2.1-.3 0-.1.1-.2.2-.3s.2-.1.3-.2c.1 0 .2-.1.3 0 .1 0 .3 0 .4.1.1 0 .2.1.3.2s.2.2.2.3c0 .1.1.2.1.4h1.1c0-.4-.1-.9-.4-1.2-.3-.3-.7-.6-1.1-.6v-1h-.8v.8c-.5 0-.9.2-1.2.6-.3.3-.5.8-.4 1.2 0 1 .7 1.5 1.8 1.9.9.3 1.3.6 1.3 1 0 .1 0 .3-.1.4-.1.1-.1.2-.2.3-.1.1-.2.1-.3.2-.2.1-.4.1-.5.1z" />
  </svg>
);

const ForwardRef = forwardRef(KijijiSendMoneyIcon);
export default ForwardRef;
