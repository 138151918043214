import { SVGProps, Ref, forwardRef } from "react";

const TagIcon = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 22 22"
    fill="none"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Tag Icon"
    {...props}
  >
    <title id="Tag Icon">Tag Icon</title>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M7 7h.01M1 4.2v4.475c0 .489 0 .733.055.963a2 2 0 0 0 .24.579c.123.201.296.374.642.72l7.669 7.669c1.188 1.188 1.782 1.782 2.467 2.004a3 3 0 0 0 1.854 0c.685-.222 1.28-.816 2.467-2.004l2.212-2.212c1.188-1.188 1.782-1.782 2.004-2.467a3 3 0 0 0 0-1.854c-.222-.685-.816-1.28-2.004-2.467l-7.669-7.669c-.346-.346-.519-.519-.72-.642a2 2 0 0 0-.579-.24C9.408 1 9.164 1 8.675 1H4.2c-1.12 0-1.68 0-2.108.218a2 2 0 0 0-.874.874C1 2.52 1 3.08 1 4.2ZM7.5 7a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Z"
    />
  </svg>
);

const ForwardRef = forwardRef(TagIcon);
export default ForwardRef;
