import { SVGProps, Ref, forwardRef } from "react";

const AppMicrosoftColorIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 21 21"
    ref={ref}
    aria-label="App Microsoft Color Icon"
    {...props}
  >
    <path fill="#f25022" d="M1 1h9v9H1z" />
    <path fill="#00a4ef" d="M1 11h9v9H1z" />
    <path fill="#7fba00" d="M11 1h9v9h-9z" />
    <path fill="#ffb900" d="M11 11h9v9h-9z" />
  </svg>
);

const ForwardRef = forwardRef(AppMicrosoftColorIcon);

export default ForwardRef;
