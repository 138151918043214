import { SVGProps, Ref, forwardRef } from "react";

const ArrowRightIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="none"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Arrow Right Icon"
    {...props}
  >
    <title id="Arrow Right Icon">Arrow Right Icon</title>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M5 12h14m0 0-7-7m7 7-7 7"
    />
  </svg>
);

const ForwardRef = forwardRef(ArrowRightIcon);

export default ForwardRef;
