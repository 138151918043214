import { SVGProps, Ref, forwardRef } from "react";

const FormatAlignRightIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="none"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Format Align Right Icon"
    {...props}
  >
    <title id="Format Align Right Icon">Format Align Right Icon</title>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M21 10H8m13-4H4m17 8H4m17 4H8"
    />
  </svg>
);

const ForwardRef = forwardRef(FormatAlignRightIcon);
export default ForwardRef;
