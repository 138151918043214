import { forwardRef, Ref, SVGProps } from "react";

const StarHalfIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Star Half Icon"
    {...props}
  >
    <title>Star Half Icon</title>
    {/* Outer star outline */}
    <path
      d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
    />
    {/* Half-filled star mask */}
    <mask id="half-mask">
      <rect x="0" y="0" width="12" height="24" fill="white" />
    </mask>
    <path
      d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"
      fill="currentColor"
      mask="url(#half-mask)"
    />
  </svg>
);

export default forwardRef(StarHalfIcon);
