import { SVGProps, Ref, forwardRef } from "react";

const ShieldPositiveIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 18 22"
    fill="none"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Shield Positive Icon"
    {...props}
  >
    <title id="Shield Positive Icon">Shield Positive Icon</title>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M9 13.5v-6m-3 3h6m5 .5c0 4.908-5.354 8.478-7.302 9.615-.221.129-.332.194-.488.227a1.137 1.137 0 0 1-.42 0c-.156-.034-.267-.098-.488-.227C6.354 19.478 1 15.908 1 11V6.217c0-.799 0-1.199.13-1.542a2 2 0 0 1 .548-.79c.276-.243.65-.383 1.398-.664l5.362-2.01c.208-.078.312-.117.419-.133a1 1 0 0 1 .286 0c.107.016.21.055.419.133l5.362 2.01c.748.28 1.123.421 1.398.664a2 2 0 0 1 .547.79c.131.343.131.743.131 1.542V11Z"
    />
  </svg>
);

const ForwardRef = forwardRef(ShieldPositiveIcon);
export default ForwardRef;
