import { SVGProps, Ref, forwardRef } from "react";

const RRCoverageWarrantyIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Coverage Warranty Icon"
    {...props}
  >
    <title id="Coverage Warranty Icon">Coverage Warranty Icon</title>
    <path
      d="M12 2.7C9.6 4.8 6.6 6 3.4 6.2c-.2 3.1.4 6.2 2 8.9 1.5 2.7 3.8 4.9 6.6 6.2 2.8-1.4 5.1-3.5 6.6-6.2s2.2-5.8 2-8.9C17.4 6 14.4 4.8 12 2.7z"
      style={{
        fill: "#d5f8ff",
      }}
    />
    <path
      d="M12 2.2C9.5 4.4 6.3 5.8 3 6c-.3 3.2.5 6.5 2.1 9.3 1.6 2.8 4 5.1 6.9 6.5 2.9-1.4 5.3-3.7 6.9-6.5 1.6-2.8 2.3-6 2.1-9.3-3.3-.2-6.5-1.6-9-3.8zm0 1.2c2.3 1.9 5.2 3 8.1 3.4.1 1.7-.2 3.4-.7 5H12V3.4zM4.6 12H12v8.7c-3.5-1.8-6.1-4.9-7.4-8.7z"
      style={{
        fill: "#00cfeb",
      }}
    />
    <path
      d="m12 22.4-.4-.2c-2.9-1.5-5.4-3.7-7-6.6-1.6-2.8-2.3-6.1-2-9.4l.1-.7h.7c3-.2 5.8-1.4 8.1-3.3l.5-.6.6.5c2.2 2 5.1 3.2 8.1 3.3h.7l.1.7c.3 3.3-.5 6.5-2.1 9.4-1.6 2.9-4 5.1-7 6.6l-.4.3zM4.2 7.1c-.1 2.7.6 5.3 1.9 7.7 1.4 2.4 3.4 4.4 5.9 5.7 2.5-1.3 4.5-3.3 5.9-5.7 1.3-2.3 2-5 1.9-7.7-2.8-.3-5.6-1.5-7.8-3.2-2.2 1.7-4.9 2.9-7.8 3.2z"
      style={{
        fill: "#293edc",
      }}
    />
  </svg>
);

const ForwardRef = forwardRef(RRCoverageWarrantyIcon);
export default ForwardRef;
