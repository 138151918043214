import { SVGProps, Ref, forwardRef } from "react";

const FormatColorTextIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="none"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Format Color Text Icon"
    {...props}
  >
    <title id="Format Color Text Icon">Format Color Text Icon</title>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M9 11.222 12.778 15m-4.803 5.975C6.608 22.342 4 22 2 22c1.025-2-.342-4.608 1.025-5.975a3.5 3.5 0 1 1 4.95 4.95Zm3.947-5.05 9.137-9.869a2.204 2.204 0 0 0-3.116-3.115l-9.868 9.137c-.51.472-.765.709-.913.96a2 2 0 0 0-.038 1.967c.139.258.385.503.876.995.491.491.737.737.994.876a2 2 0 0 0 1.968-.038c.251-.149.487-.404.96-.914Z"
    />
  </svg>
);

const ForwardRef = forwardRef(FormatColorTextIcon);
export default ForwardRef;
