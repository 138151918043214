import { SVGProps, Ref, forwardRef } from "react";

const FlatClipboardIconIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 73 86"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Flat Clipboard Icon"
    {...props}
  >
    <path
      d="M63.0242 3.84741H9.70239C4.54454 3.84741 0.363281 7.96731 0.363281 13.0495V76.3629C0.363281 81.4451 4.54454 85.565 9.70239 85.565H63.0242C68.182 85.565 72.3633 81.4451 72.3633 76.3629V13.0495C72.3633 7.96731 68.182 3.84741 63.0242 3.84741Z"
      fill="#293EDC"
    />
    <path d="M65.0513 11.6873H7.67578V77.7301H65.0513V11.6873Z" fill="white" />
    <path
      d="M51.3985 0.564941H21.3335C18.9024 0.564941 16.9316 2.50682 16.9316 4.90225V11.3237C16.9316 13.7192 18.9024 15.661 21.3335 15.661H51.3985C53.8296 15.661 55.8004 13.7192 55.8004 11.3237V4.90225C55.8004 2.50682 53.8296 0.564941 51.3985 0.564941Z"
      fill="#00CFEB"
    />
    <path
      d="M36.3625 25.877C47.3231 25.877 56.2308 34.654 56.2308 45.4537C56.2308 56.2535 47.3231 65.0305 36.3625 65.0305C25.4019 65.0305 16.4941 56.2535 16.4941 45.4537C16.4941 34.654 25.4019 25.877 36.3625 25.877V25.877Z"
      fill="#D5F8FF"
    />
    <path
      d="M35.6655 54.7685L25.8223 47.2768L28.8262 43.4413L34.7716 47.9681L43.6741 36.5436L47.5719 39.4932L35.6655 54.7685Z"
      fill="#00CFEB"
    />
  </svg>
);

const ForwardRef = forwardRef(FlatClipboardIconIcon);

export default ForwardRef;
