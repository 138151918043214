import { SVGProps, Ref, forwardRef } from "react";

const PasswordIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    aria-hidden="true"
    // style={{
    //   enableBackground: "new 0 0 32 32",
    // }}
    viewBox="0 0 32 32"
    fill="currentColor"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Password Icon"
    {...props}
  >
    <path
      d="M.7 12s0-2.7 2.7-2.7h25.3s2.7 0 2.7 2.7v9.3s0 2.7-2.7 2.7H3.3S.6 24 .6 21.3V12z"
      style={{
        fill: "#e56f00",
      }}
    />
    <path
      d="M3.3 13.3s0-1.3 1.3-1.3h22.7s1.3 0 1.3 1.3V20s0 1.3-1.3 1.3H4.7s-1.3 0-1.3-1.3v-6.7z"
      style={{
        opacity: 0.7,
        fill: "#fff",
        // enableBackground: "new",
      }}
    />
    <path
      d="M13.3 14.7c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2zm-5.3 0c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2zm10.7 0c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2zm5.3 0c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2z"
      style={{
        fill: "#263238",
      }}
    />
  </svg>
);

const ForwardRef = forwardRef(PasswordIcon);

export default ForwardRef;
