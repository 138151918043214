import { SVGProps, Ref, forwardRef } from "react";

const ChevronDownIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="none"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Chevron Down Icon"
    {...props}
  >
    <title id="Chevron Down Icon">Chevron Down Icon</title>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m6 9 6 6 6-6"
    />
  </svg>
);

const ForwardRef = forwardRef(ChevronDownIcon);

export default ForwardRef;
