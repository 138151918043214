import classNames from "classnames";

type Props = {
  heading: string;
  body: string;
  textSize?: "sm" | "md";
};

export const HeadingBodyText = ({ heading, body, textSize = "md" }: Props) => (
  <>
    {textSize === "md" ? (
      <h3 className="pb-1 text-md font-semibold">{heading}</h3>
    ) : (
      <h5 className="pb-1 text-2xl font-semibold">{heading}</h5>
    )}
    <p
      className={classNames("text-lg font-normal text-secondary", {
        "text-sm": textSize === "sm",
      })}
    >
      {body}
    </p>
  </>
);
