import { SVGProps, Ref, forwardRef } from "react";

const CarFrontIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 22 18"
    fill="none"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Front Car Icon"
    {...props}
  >
    <title id="Front Car Icon">Front Car Icon</title>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M4 10h3M1 6l2 1 1.27-3.812c.263-.787.394-1.18.637-1.471a2 2 0 0 1 .803-.578C6.062 1 6.477 1 7.306 1h7.388c.829 0 1.244 0 1.597.139a2 2 0 0 1 .802.578c.243.29.374.684.636 1.471L19 7l2-1m-6 4h3M5.8 7h10.4c1.68 0 2.52 0 3.162.327a3 3 0 0 1 1.311 1.311C21 9.28 21 10.12 21 11.8v2.7c0 .465 0 .697-.038.89a2 2 0 0 1-1.572 1.572c-.193.038-.425.038-.89.038H18a2 2 0 0 1-2-2 .5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5 2 2 0 0 1-2 2h-.5c-.465 0-.697 0-.89-.038a2 2 0 0 1-1.572-1.572C1 15.197 1 14.965 1 14.5v-2.7c0-1.68 0-2.52.327-3.162a3 3 0 0 1 1.311-1.311C3.28 7 4.12 7 5.8 7Z"
    />
  </svg>
);

const ForwardRef = forwardRef(CarFrontIcon);
export default ForwardRef;
