import { SVGProps, Ref, forwardRef } from "react";

const SelectorIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="none"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Selector Icon"
    {...props}
  >
    <title id="Selector Icon">Selector Icon</title>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m7 15 5 5 5-5M7 9l5-5 5 5"
    />
  </svg>
);

const ForwardRef = forwardRef(SelectorIcon);
export default ForwardRef;
