import { SVGProps, Ref, forwardRef } from "react";

const RRMapIcon = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    width="44"
    height="53"
    viewBox="0 0 44 53"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    aria-label="RR Map Icon"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M38.5646 36.7318C41.9054 32.9253 43.9309 27.9358 43.9309 22.4731C43.9309 10.53 34.2491 0.848145 22.3059 0.848145C10.3628 0.848145 0.680908 10.53 0.680908 22.4731C0.680908 27.9358 2.70637 32.9253 6.04726 36.7318L6.04245 36.7366L6.11914 36.8133C6.69711 37.4652 7.31385 38.0819 7.96577 38.6599L22.3059 53.0001L36.646 38.6599C37.298 38.0819 37.9147 37.4652 38.4927 36.8133L38.5694 36.7366L38.5646 36.7318Z"
      fill="#293EDD"
    />
    <path
      d="M14.7485 25.3503L13.132 25.3569L13.1569 31.4845L10.0372 31.4969L9.97369 15.5825L16.1911 15.5577C19.2881 15.5453 21.2725 17.6698 21.2834 20.431C21.2922 22.6534 19.997 24.3421 17.9119 24.9561L21.3499 31.4516L17.8937 31.4655L14.7485 25.3503ZM15.6362 22.6753C17.2074 22.6687 18.1236 21.7678 18.1185 20.466C18.1134 19.1643 17.1891 18.2473 15.6187 18.2539L13.1043 18.2641L13.1218 22.6855L15.6362 22.6753Z"
      fill="#D5F8FF"
    />
    <path
      d="M28.0375 25.2971L26.421 25.3037L26.4459 31.4313L23.3262 31.4437L23.2627 15.5293L29.4801 15.5044C32.5771 15.492 34.5615 17.6166 34.5724 20.3778C34.5812 22.6001 33.286 24.2888 31.2009 24.9028L34.6389 31.3984L31.1827 31.4123L28.0375 25.2971ZM28.9252 22.6221C30.4964 22.6155 31.4126 21.7146 31.4075 20.4128C31.4024 19.0658 30.4781 18.1941 28.9077 18.2007L26.3933 18.2109L26.4108 22.6323L28.9252 22.6221Z"
      fill="#D5F8FF"
    />
  </svg>
);

const ForwardRef = forwardRef(RRMapIcon);

export default ForwardRef;
