import { SVGProps, Ref, forwardRef } from "react";

const FormatAlignJustifyIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="none"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Format Align Justify Icon"
    {...props}
  >
    <title id="Format Align Justify Icon">Format Align Justify Icon</title>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M21 10H3m18 8H3M21 6H3m18 8H3"
    />
  </svg>
);

const ForwardRef = forwardRef(FormatAlignJustifyIcon);
export default ForwardRef;
