import { SVGProps, Ref, forwardRef } from "react";

const CursorIcon = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="none"
    width="1em"
    height="1em"
    ref={ref}
    aria-label="Cursor Icon"
    {...props}
  >
    <title id="Cursor Icon">Cursor Icon</title>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M4.407 3.414c-.458-.135-.687-.202-.843-.144a.5.5 0 0 0-.294.294c-.058.156.009.385.144.843l4.206 14.3c.125.426.188.639.314.738a.5.5 0 0 0 .39.1c.158-.027.315-.184.629-.498L12 16l4.434 4.434c.198.198.297.297.411.334a.5.5 0 0 0 .31 0c.114-.037.213-.136.41-.334l2.87-2.868c.197-.198.296-.297.333-.412a.5.5 0 0 0 0-.309c-.037-.114-.136-.213-.334-.41L16 12l3.047-3.047c.314-.314.47-.47.497-.63a.5.5 0 0 0-.1-.39c-.098-.125-.311-.188-.737-.313l-14.3-4.206Z"
    />
  </svg>
);

const ForwardRef = forwardRef(CursorIcon);
export default ForwardRef;
