import classNames from "classnames";
import { ReactNode } from "react";
import { CheckCircleIcon, WarningIcon } from "@talladega/icons";
import { Status } from "../types";

type FeedbackProps = {
  children: ReactNode;
  status?: Status;
};

export const Feedback = ({ status = "default", children }: FeedbackProps) => {
  const statusColor: Record<string, string> = {
    default:
      "border-other-light-100 focus:border-primary-500 focus:shadow-primary-500/8",
    error: "border-error focus:border-error focus:shadow-error/8",
  };

  const textColor: Record<string, string> = {
    default: "text-gray",
    info: "text-primary-500",
    success: "text-tertiary-apple-600",
    warning: "text-warning",
    error: "text-error-primary",
  };

  return (
    <div className={classNames("flex items-center", statusColor[status] || "")}>
      {(status === "error" || status === "warning") && (
        <WarningIcon
          className={classNames(
            "h-6 w-6 pr-2 shrink-0",
            textColor[status] || ""
          )}
        />
      )}
      {status === "success" && (
        <CheckCircleIcon
          className={classNames(
            "h-6 w-6 pr-2 shrink-0",
            textColor[status] || ""
          )}
        />
      )}
      <div
        className={classNames("text-sm font-normal", textColor[status] || "")}
      >
        {children}
      </div>
    </div>
  );
};
